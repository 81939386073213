export const STRINGS = {
  COMPREHENSIVE:"A Comprehensive",
  PHYSICAL:"Physical Assessment Report",
  PHYSICAL1:"Physical Reassessment Report",
  MINDBODY:"Mindbody Data Validation",
  MEDICAL:"Medical History",
  BODY:"Body Assessment",
  BODY1:"Body Reassessment",
  LIFESTYLE:"Lifestyle",
  FAMILY:"Family History",
  ORTHO:"Ortho",
  BODY_MASS:"Body Mass Index",
  VISCERAL:"Visceral Fat",
  GOALS:"Goals",
  
}

export const orthoMapping = {
    rightWrist: "Do you have any pain or issues or previous injuries in your Right Wrist?",
    hernia: "Do you or have you had a Hernia?",
    rightKnee: "Do you have any pain or issues or previous injuries in your Right Knee?",
    leftWrist:  "Do you have any pain or issues or previous injuries in your Left Wrist?",
    leftKnee:  "Do you have any pain or issues or previous injuries in your Left Knee?",
    rightFoot:  "Do you have any pain or issues or previous injuries in your Right Foot?",
    back:  "Do you have any pain or issues or previous injuries in your Back?",
    leftHip:  "Do you have any pain or issues or previous injuries in your Left Hip?",
    neck: "Do you have any pain or issues in your Neck?",
    rightShoulder: "Do you have any pain or issues or previous injuries in your Right Shoulder?",
    rightElbow: "Do you have any pain or issues or previous injuries in your Right Elbow?",
    leftAnkle: "Do you have any pain or issues or previous injuries in your Left Ankle?",
    leftElbow: "Do you have any pain or issues or previous injuries in your Left Elbow?",
    leftShoulder: "Do you have any pain or issues or previous injuries in your Left Shoulder?",
    rightHand: "Do you have any pain or issues or previous injuries in your Right Hand?",
    leftHand: "Do you have any pain or issues or previous injuries in your Left Hand?",
    rightAnkle: "Do you have any pain or issues or previous injuries in your Right Ankle?",
    leftFoot: "Do you have any pain or issues or previous injuries in your Left Foot?",
    rightHip: "Do you have any pain or issues or previous injuries in your Right Hip?"
  }

  export const medMapping = {
    bp:"Are you currently being treated for high blood pressure?",
    chestPain:"Do you feel pain in your chest during physical activity?",
    cholesterol:"Has your cholesterol been measured at greater than 200 mg/dl?",
    dizziness:"Have you had any episodes of dizziness, fainting or unexplained shortness of breath?",
    heart:"Do you have a history of heart disease or any heart related conditions?",
    medications:"Are you currently taking any other medications?",
    metobolic:"Do you have a history of diabetes or other metabolic disease (thyroid, renal, liver)?",
    paceMaker:"Do you have a pacemaker or implantable defibrillator?",
    physical:"Do you have a history of any of the following?",
    pulmonary:"Do you have a history of pulmonary disease, asthma or difficulty breathing?",
    overweight:"Do you consider yourself overweight?",
    pregnant:"Are you pregnant?",
    heartOther:"Heart Other",
    physicalOther:"Physical Other",
    paceMakerOther:"Pacemaker Other",
    chestPainOther:"Chest Pain Other",
    cholesterolOther:"Cholesterol Other",
    dizzinessOther:"Dizziness Other",
    pulmonaryOther:"Pulmonary Other",
    medicationsOther:"Medications Other",
    bpOther:"Bp Other"
  }

  export const famMap = {
    cancer:"Have your mother, father, or siblings been diagnosed with Cancer?",
    heartAttack:"Have your mother, father, or siblings experienced a heart attack prior to age 55?",
    heartSurgery:"Have your mother, father, or siblings experienced heart surgery prior to age 55?",
    highCholesterol:"Have your mother, father, or siblings been diagnosed with high cholesterol?",
    obesity:"Have your mother, father, or siblings ever suffered with Obesity?",
    stroke:"Have your mother, father, or siblings been had aStroke prior to age 50?" 
  }

  export const lifestyleMap = {
    alcohol:"Do you drink alcohol? If so, how many per week?",
    breakfast:"What is a typical breakfast?",
    dinner:"What is a typical dinner?",
    exercise:"How frequently do you exercise?",
    exerciseProgram:"If applicable, please describe your current fitness program",
    lunch:"What is a typical lunch?",
    sleepHours:"How many hours of sleep do you typically get per night?",
    feelRestedAfterSleep:"Do you wake up feeling well rested?",
    smoker:"Are you, or have you been, an e-cigarette smoker?",
    smokeYears:"If so, for how long?",
    smokeQuitDate:"If quit when?",
    snacks:"How often do you have snacks and what do you typically have?",
    stress:"Please Rate Your Daily Stress Levels",
    vaper:"Are you, or have you been, an e-cigarette?",
    toAssessor:"Based on what client told you about meals, should we pitch Nutrition?",
    waterInOz:"How much water do you consume throughout the day?",
  }

  export const goalMap = {
    goalWeight:"Imagine this time next year, how much do you weigh?",
    primaryGoals:"Do you have any goals in mind for yourself",
    idealTrainTime:"Do you have an ideal start time that works best?",
    secondaryGoals:"Do you have a second goal you would also like to accomplish?",
    trainDay:"Do you have specific days that you are looking to train?",
    trainEndTime:"What time do you need to be finished with your workouts?",
    trainStarTime:"What is the earliest time you can start your workouts?",
    
  }

  export const prgMap = {
    agilityLadder: "Agility Ladder",
    bearCrawl:"Bear Crawl",
    floorDownUp:"Floor-Down & Up",
    forwardBend:"Forward Bend",
    gait:"Gait",
    highKnees:"High Knees",
    jumpingJacks:"Jumping Jacks",
    lateralAndPosteriorKicks:"Lateral And Posterior Kicks",
    lateralKicks:"Lateral Kicks",
    overHeadReach:"Over Head Reach",
    plank:"Plank",
    pushUp:"Push Ups",
    shoulderPress:"Shoulder Press",
    shuffle:"Shuffle",
    sidePlankRL:"Side Plank Right/Left",
    sideStep:"Side-Step",
    singleLegBalanceLeft:"Single Leg Balance-Left",
    singleLegBalanceRight:"Single Leg Balance-Right",
    skaters:"Skaters",
    squatSitToStand:"Squat/Sit to Stand",
    squats:"Squats",
    standingCrossCrunch:"Standing CrossCrunch",
    standingRow:"Standing Row"
  }

  export const tstMap = {
    alternateHealTouch:"Alternate head touch",
    bigArmCircles:"Big arm circles",
    chairSitToStand:"Chain sit to stand",
    dominantSeatedArmCurl:"Dominant seated arm curl",
    elevatedPushUp:"Elevated pushup",
    frogJump:"Frog jump",
    lateralJump:"Lateral jump",
    plankOrShoulderTouch:"Plank/Shoulder touch",
    pushUp:"Push Ups",
    s2sEnduranceJump:"Side-to-Side Endurance Jump",
    sitToStand:"Sit to stand",
    squatJump:"Squat Jump",
    stepTest:"Step test",
  }

  export const allFit = {
    alternateHealTouch:"Alternate head touch",
    bigArmCircles:"Big arm circles",
    chairSitToStand:"Chain sit to stand",
    dominantSeatedArmCurl:"Dominant seated arm curl",
    elevatedPushUp:"Elevated pushup",
    frogJump:"Frog jump",
    lateralJump:"Lateral jump",
    plankOrShoulderTouch:"Plank/Shoulder touch",
    pushUp:"Push Ups",
    s2sEnduranceJump:"Side-to-Side Endurance Jump",
    sitToStand:"Sit to stand",
    squatJump:"Squat Jump",
    stepTest:"Step test",
    agilityLadder: "Agility Ladder",
    bearCrawl:"Bear Crawl",
    floorDownUp:"Floor-Down & Up",
    forwardBend:"Forward Bend",
    gait:"Gait",
    highKnees:"High Knees",
    jumpingJacks:"Jumping Jacks",
    lateralAndPosteriorKicks:"Lateral And Posterior Kicks",
    lateralKicks:"Lateral Kicks",
    overHeadReach:"Over Head Reach",
    plank:"Plank",
    pushUp:"Push Ups",
    shoulderPress:"Shoulder Press",
    shuffle:"Shuffle",
    sidePlankRL:"Side Plank Right/Left",
    sideStep:"Side-Step",
    singleLegBalanceLeft:"Single Leg Balance-Left",
    singleLegBalanceRight:"Single Leg Balance-Right",
    skaters:"Skaters",
    squatSitToStand:"Squat/Sit to Stand",
    squats:"Squats",
    standingCrossCrunch:"Standing CrossCrunch",
    standingRow:"Standing Row"
  }

  export const mindMap = {
    AddressLine1: "Address",
    BirthDate: "Date Of Birth",
    City: "City Name",
    Email: "Email Address",
    FirstName: "First Name",
    Gender: "Gender",
    LastName: "Last Name",
    MobilePhone: "Mobile",
    PostalCode: "Zip Code",
    SendAccountEmails: "Receive Account Emails",
    SendPromotionalEmails: "Receive Promotional Emails",
    SendScheduleEmails: "Receive Schedule Emails",
    EmergencyContactInfoName:"Emergency Contact Info Name",
    EmergencyContactInfoPhone:"Emergency Contact Info Phone",
    EmergencyContactInfoRelationship:"Emergency Contact Info Relationship",
    State: "State",
    ReferredBy:"Referred By",

  };

  export const bodyMap = {
    ageInYears: "Age",
    bicepInInches: "Biceps",
    bodyAge: "Body Age",
    bodyFatPercentage: "Body Fat Percentage",
    bodyMassIndex: "Body Mass Index",
    calfInInches: "Calf",
    chestInInches: "Chest",
    height: "Height",
    muscleMassPercentage: "Muscle Mass Percentage",
    thighInInches: "Thigh",
    visceralFat: "Visceral Fat",
    waistInInches: "Waist",
    weightInLBS: "Weight"
  };

  export const adaptMap = {
    mentalEmotionalAttributes:"Please select any mental and emotional attributes that the client experiences.",
    behavioralTriggers:"Please indicate any behavioral triggers that the client may express.",
    challengingBehavior:"If a challenging behavior happens what are some ways a trainer can deescalate the situation?",
    moviesAndTelevision:"Movies & Television",
    music:"Music",
    sports:"Sports",
    hobbies:"Hobbies",
    videoGames:"Video Games",
    utilizeReinforcers:"Do you utilize reinforcers when the client becomes resistant?",
    howToImplementReinforcers:" If yes, how can we implement them during a session, if necessary?",
    nonVerbalCues:"Are there any non-verbal cues or actions a trainer can utilize to help communicate better with the client? Consider gestures, modeling, body position & tactile cues.",
    clientMood:"What mood was the client presenting during the Assessment?",
    anxietyProvokingBehavior:"Was the client displaying any of the following anxiety-provoking behaviors?",
    outburstBehavior:"Did the client exhibit any of the following outburst behaviors?",
    communicationStyle:"What was the communication style of the client?",
    isCareGiverRequired:"Do you believe this client will require a caregiver to be present during sessions?",
    clientCommunicationMode:"Did the client communicate with you independently or through caregiver?",
    careGiverInvolvement:"How would you describe the caregiver’s involvement during the Assessment?",
  }
  export const orthoArr = [
    "rightFoot",
    "leftFoot",
    "rightAnkle",
    "leftAnkle",
    "rightKnee",
    "leftKnee",
    "rightHip",
    "leftHip",
    "back",
    "rightShoulder",
    "leftShoulder",
    "neck",
    "rightElbow",
    "leftElbow",
    "rightWrist",
    "leftWrist",  
    "rightHand",
    "leftHand",
   
    "hernia",
  ]


  export const fitArr =[
    "alternateHealTouch",
    "bigArmCircles",
    "chairSitToStand",
    "dominantSeatedArmCurl",
    "elevatedPushUp",
    "frogJump",
    "lateralJump",
    "plankOrShoulderTouch",
    "pushUp",
    "s2sEnduranceJump",
    "sitToStand",
    "squatJump",
    "stepTest",
    "agilityLadder",
    "bearCrawl",
    "floorDownUp",
    "forwardBend",
    "gait",
    "highKnees",
    "jumpingJacks",
    "lateralAndPosteriorKicks",
    "lateralKicks",
    "overHeadReach",
    "plank",
    "shoulderPress",
    "shuffle",
    "sidePlankRL",
    "sideStep",
    "singleLegBalanceLeft",
    "singleLegBalanceRight",
    "skaters",
    "squatSitToStand",
    "squats",
    "standingCrossCrunch",
    "standingRow"
]

  export const famArr = ["heartAttack","heartSurgery","highCholesterol","stroke","cancer","obesity"]
  export const goalArr = ["primaryGoals","secondaryGoals","goalWeight","trainDay","trainStarTime","trainEndTime"]
  export const mindArr = ["FirstName", "LastName","AddressLine1","City","State","Country","PostalCode","Email","MobilePhone","SendAccountEmails","SendScheduleEmails","SendPromotionalEmails","BirthDate", "Gender","EmergencyContactInfoName","EmergencyContactInfoRelationship", "EmergencyContactInfoPhone",  "ReferredBy","clientType"]
  export const bodyArr = ["ageInYears","height","weightInLBS","bodyAge","bodyFatPercentage","muscleMassPercentage","visceralFat","bodyMassIndex","bicepInInches","chestInInches","waistInInches","thighInInches","calfInInches"]
  export const reAsBodyArr = [ "bodyAge","height","weightInLBS","bodyFatPercentage","muscleMassPercentage","visceralFat","bicepInInches","chestInInches","waistInInches","thighInInches","calfInInches"]


  export function order(val,ord) {
    let x = {}
    ord.forEach(key => { 

        x[key] = val[key];
      
    });
   return x
  }