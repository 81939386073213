import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { medMapping, orthoMapping, famMap, lifestyleMap, goalMap, prgMap, tstMap, mindMap, bodyMap, adaptMap, STRINGS, order, orthoArr, famArr, mindArr, goalArr, bodyArr, allFit, reAsBodyArr } from "./Constant";
import { calAge, checkForNoRecord, comVal, removeEmptyStringValues, replaceKeys } from "./Helper";

function Reassess() {
  const params = useParams();
  const [mind, setMind] = useState({})
  const [data,setData] = useState({})
  const [bodyAssess, setBodyAssess] = useState([])
  const [program, setProgram] = useState([])
  const [posture, setPosture] = useState([])
  const [test, setTest] = useState([])
  const [flag, setFlag] = useState({
    med: false,
    ortho: false,
    family: false,
    lifestyle: false,
    goal: false,
    body: false,
    program: false,
    test: false,
    adapt: false,

  })


  useEffect(() => {

    const reAssessData = async () => {
      const url = `https://devapigymguys.resourcifi.tech/client/reassessment/${params.id}`;
      const response = await axios.get(url);
      console.log("RD",response)
      setData(response?.data?.data)
      let mindSeq = order(response?.data?.data?.mindBodyData, mindArr)
      const mindData = replaceKeys(mindSeq, mindMap)
      setMind(mindData)
      let bodArr = order(response?.data?.data?.bodyAssessment,reAsBodyArr)
      bodArr = removeEmptyStringValues(bodArr)
      bodArr = replaceKeys(bodArr,bodyMap)
      const prgSt = comVal(bodArr, bodyMap, true)
      const arrayRe = Object.entries(bodArr).map(([key, value]) => ({ [key]: value }));
      setBodyAssess(arrayRe)
      const oldFit = comVal(response?.data?.data?.fitnessAssessment, allFit, true)
      setProgram(oldFit)
      const newFit = comVal(response?.data?.data?.fitnessAssessmentNew, allFit, true)
      setPosture(newFit)

    }
    // Define the async function to fetch data
    const fetchData = async () => {
      try {
        const url = `https://devapigymguys.resourcifi.tech/client/${params.id}`;
        const response = await axios.get(url);
        // const bodySeq = order(response?.data?.data?.bodyAssessment, bodyArr)
        // const bodyAs = removeEmptyStringValues(bodySeq)
        // const bodySt = replaceKeys(bodyAs, bodyMap)
        // // console.log(bodyAs,"BODY")
        // setBodyAssess(bodySt)
        const prgSt = comVal(response?.data?.data?.fitnessAssessment?.program, prgMap, true)
        setProgram(prgSt)
        const testSt = comVal(response?.data?.data?.fitnessAssessment?.test, tstMap, true)
        setTest(testSt)
        setPosture([
          { label: "Posture", value: response?.data?.data?.fitnessAssessment?.posture },
          { label: "Resting Heart Rate", value: response?.data?.data?.fitnessAssessment?.restingHeartRate }
        ]
        )
        // const isBody = checkForNoRecord(bodySt)
        const isProgram = checkForNoRecord(prgSt)
        const isTest = checkForNoRecord(testSt)
        setFlag({
          body: "",
          program: isProgram,
          test: isTest,

        })

      } catch (err) {
      } finally {
      }
    };

    // Call the function
    fetchData();
    reAssessData()
  }, []);

  return (
    <div style={
      {
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        paddingBottom: "32px"
      }
    }>
      <div
        style={
          {
            background: `url(https://app-development-resourcifi.s3.amazonaws.com/Uploads/1724931802190/back.png)`,
            height: "456px"
          }
        }>
        <div style={
          {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "18px 30px 0px 30px",
          }
        }>
          <div style=
            {{
              fontFamily: '"Oswald", sans-serif',
              fontWeight: "600",
              color: "white",
              fontSize: "16px"
            }}>
            Summary Report
          </div>
          <div>
            <div>
              <img src={`https://app-development-resourcifi.s3.amazonaws.com/Uploads/1724931740479/gym.png`} alt="gym-guyz" />
            </div>
            <div>
              <img src={`https://app-development-resourcifi.s3.amazonaws.com/Uploads/1724931740448/txt.png`} alt="gym-txt" />
            </div>
          </div>
        </div>
        <div style={
          {
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 30px 0px 30px",
          }
        }>
          <div style={
            {
              marginTop: "96px"
            }
          }>
            <div style=
              {{
                fontFamily: '"Oswald", sans-serif',
                fontWeight: "700",
                color: "white",
                fontSize: "24px"
              }}>
              {STRINGS.COMPREHENSIVE}
            </div>
            <div style=
              {{
                fontFamily: '"Oswald", sans-serif',
                fontWeight: "700",
                color: "white",
                fontSize: "24px"
              }}>
              {STRINGS.PHYSICAL1}
            </div>
            <div style={
              {
                borderTop: "2.82px solid #E4002B",
                marginTop: "11.3px"
              }
            }>

            </div>
            <div style={
              {
                display:"flex",
                gap:"32px"
              }
            }>
            <div style={
              {
                display: "flex",
                gap: "32px",
                marginTop: "11.3px",
                borderRight:"1px solid #FFFFFF"
              }
            }>
              <div>
                <div style={
                  {
                    fontFamily: '"Oswald", sans-serif',
                    fontWeight: "500",
                    color: "white",
                    fontSize: "22px"
                  }
                }>
                  {`${mind["First Name"]} ${mind["Last Name"]}`}
                </div>
                <div style={
                  {
                    fontFamily: '"Oswald", sans-serif',
                    fontWeight: "400",
                    color: "white",
                    fontSize: "16px"
                  }
                }>
                  {`${mind.Gender} | ${calAge(mind["Date Of Birth"])}`}
                </div>
              </div>
              <div>
              </div>
            </div>
            {/* <div style={{
              borderRight:".5px solid #FFFFFF",
              marginTop: "11.3px"
            }}>

            </div> */}
            <div style={
              {
                display: "flex",
                gap: "32px",
                marginTop: "11.3px"
              }
            }>
              <div>
                <div style={
                  {
                    fontFamily: '"Oswald", sans-serif',
                    fontWeight: "500",
                    color: "white",
                    fontSize: "22px"
                  }
                }>
                  {`${data["mboTrainerName"]}`}
                </div>
                <div style={
                  {
                    fontFamily: '"Oswald", sans-serif',
                    fontWeight: "400",
                    color: "white",
                    fontSize: "16px"
                  }
                }>
                  {`Trainer ID: ${data["mboTrainerId"]}`}
                </div>
              </div>
              <div>
              </div>
            </div>
            </div>
          </div>
          <div style={
            {
              marginTop: "104px",
              bottom: 0
            }
          }>
            <img src={'https://app-development-resourcifi.s3.amazonaws.com/Uploads/1724931802188/Rectangle%201.png'} alt="pic" style={{ width: "300px" }} />
          </div>
        </div>
      </div>
      <div style={
        {
          margin: "0px 30px"
        }
      }>
        <div style={
          {
            // height:"43px",
            backgroundColor: "#F4F5EF",
            padding: "8px 16px",
            fontFamily: '"Oswald", sans-serif',
            fontWeight: "500",
            color: "#000000",
            fontSize: "18px"

          }
        }>
          {STRINGS.MINDBODY}
        </div>
      </div>
      <div style={
        {
          margin: "0px 30px",
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "24px",
          padding: "10px",
        }
      }>
        {Object.entries(mind).map(([key, value]) => (
          (value || value.toString() === "true" || value.toString() === "false") &&
          <div style={
            {
              display: "flex",
              flexDirection: "column",
              gap: "8px"
            }
          }>
            <div style={
              {
                fontFamily: '"Oswald", sans-serif',
                fontWeight: "400",
                color: "#000000",
                fontSize: "16px"
              }
            }>
              {key}
            </div>
            <div style={{
              fontFamily: 'Roboto, sans-serif',
              fontWeight: "400",
              color: "#666666",
              fontSize: "16px"
            }}>
              {value.toString() == "true" ? "Yes" : (value.toString() == "false" ? "No" : value.toString())}
            </div>
          </div>
        ))}
      </div>
      {!flag.body ?
        <div style={
          {
            margin: "0px 30px"
          }
        }>
          <div style={
            {
              // height:"43px",
              backgroundColor: "#F4F5EF",
              padding: "8px 16px",
              fontFamily: '"Oswald", sans-serif',
              fontWeight: "500",
              color: "#000000",
              fontSize: "18px"

            }
          }>
            {STRINGS.BODY1}
          </div>
        </div> : <div></div>
      }
      <div style={{ display: "grid",gridTemplateColumns: "repeat(2, 1fr)", gap: "6px", margin: "0px 30px"}}>
        {bodyAssess?.map((item, id) => {
          const key = Object.keys(item)[0]; // Get the key (e.g., "sq", "sq1")
          const value = item[key];
          return (
            <div style={{ flex: 1, border: "1px solid #e3e3e3", padding: "8px", borderRadius: "4px" }}>
              <div style={{ width: "100%" }}>
                <div style={
                  {
                    paddingBottom: "8px",
                    borderBottom: "1px solid #e3e3e3",
                    display: "flex",
                    alignItems: "center"
                  }

                }>
                  <span style={{ color: "#000000", fontWeight: "600", fontFamily: 'Roboto, sans-serif', fontSize: "14px" }}>{key}</span>
                </div>
                <div style={{
                  marginTop: "12px",
                  display: "flex",
                  height: "auto"
                }}>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      Previous
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {value["previous"] || "--"}
                    </div>
                  </div>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      Current
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {value["current"] || "--"}
                    </div>
                  </div>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      % Change
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {value["percentChange"] || "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>)
        })}

      </div>
      {!flag.body ?
        <div style={
          {
            margin: "0px 30px"
          }
        }>
          <div style={
            {
              // height:"43px",
              backgroundColor: "#F4F5EF",
              padding: "8px 16px",
              fontFamily: '"Oswald", sans-serif',
              fontWeight: "500",
              color: "#000000",
              fontSize: "18px"

            }
          }>
            Fitness Reassessment
          </div>
        </div> : <div></div>
      }
      <div style={{ display: "grid",gridTemplateColumns: "repeat(2, 1fr)", gap: "6px", margin: "0px 30px"}}>
        {program?.map((item, id) => {
          const key = Object.keys(item)[0]; // Get the key (e.g., "sq", "sq1")
          const value = item[key];
          const newItem = posture.find(newObj => newObj[key]);
          const newValues = newItem ? newItem[key] : {};
          console.log(newValues,newItem,"HH",newItem?.["timing"])
          
          return (
            <div style={{ flex: 1, border: "1px solid #e3e3e3", padding: "8px", borderRadius: "4px" }}>
              <div style={{ width: "100%" }}>
                <div style={
                  {
                    paddingBottom: "8px",
                    borderBottom: "1px solid #e3e3e3",
                    display: "flex",
                    alignItems: "center"
                  }

                }>
                  <span style={{ color: "#000000", fontWeight: "600", fontFamily: 'Roboto, sans-serif', fontSize: "14px" }}>{key}</span>
                </div>
                <div style={{backgroundColor:"#FFF4F6", borderRadius:"4px",padding:"4px",fontWeight:"600",fontSize:"14px",color:"#E4002B",marginTop:"16px",
                fontFamily: 'Roboto, sans-serif' }}>
                    Previous
                </div>
                <div style={{
                  marginTop: "12px",
                  display: "flex",
                  height: "auto"
                }}>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      Time
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {value["timing"] || "--"}
                    </div>
                  </div>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      Reps
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {value["reps"] || "--"}
                    </div>
                  </div>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      % Change
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {value["percentageChange"] || "--"}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", marginTop:"16px" }}>
                <div style={
                  {
                    paddingBottom: "8px",
                    borderBottom: "1px solid #e3e3e3",
                    display: "flex",
                    alignItems: "center"
                  }

                }>
                </div>
                <div style={{backgroundColor:"#FFF4F6", borderRadius:"4px",padding:"4px",fontWeight:"600",fontSize:"14px",color:"#E4002B",marginTop:"16px",
                                      fontFamily: 'Roboto, sans-serif', }}>
                    Current
                </div>
                <div style={{
                  marginTop: "12px",
                  display: "flex",
                  height: "auto"
                }}>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      Time
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {newValues?.["timing"] || "--"}
                    </div>
                  </div>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      Reps
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {newValues?.["reps"] || "--"}
                    </div>
                  </div>
                  <div style={
                    {
                      width: "33.3%",
                      display: "flex",
                      flexDirection: "column"
                    }
                  }>
                    <div style={{
                      background: "#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px"

                    }}>
                      % Change
                    </div>
                    <div style={{
                      // background:"#F4F5EF",
                      border: "1px solid #e3e3e3",
                      textAlign: "center",
                      color: "#666666",
                      fontWeight: "500",
                      padding: "4px 12px 4px 12px",
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: "14px",
                      flex: 1

                    }}>
                      {newValues?.["percentageChange"] || "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>)
        })}

      </div>
    </div>
  );
}

export default Reassess;
